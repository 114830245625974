import React, { useState, useContext, useEffect } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"

import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"

import TextField from "@material-ui/core/TextField"
// import FormControlLabel from "@material-ui/core/FormControlLabel"

import { Link } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Layout from "../Layout/layout"

import Loading from "../Shared/Loading"
import Error from "../Shared/Error"
import Copyright from "../utils/Copyright"
import SignUp from "./SignUp"
import UserHome from "../User/UserHome"
import UserContext from "../../context/UserContext"
import { navigate } from "gatsby"
import { Alert, AlertTitle } from "@material-ui/lab"
// import { check } from "prettier"
// import uiLocalStorageHook from "./UiLocalStorageHook"
// import { LoggedInContext } from "../../context/LoggedInContext"

const PasswordNew = ({ props, classes }) => {
  const { state, dispatch } = useContext(UserContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [passwordSet, setPasswordSet] = useState(false)
  const [warning, setWarning] = useState("")
  // const confirm = Object.values(props.props.params)

  const pageInfo = "" + Object.values(props.params).shift()

  const pageParams = pageInfo.split("/")
  const iVariable = pageParams[0]
  const tVariable = pageParams[1]

  const [handleSubmit, { loading, error }] = useMutation(
    NEW_PASSWORD_MUTATION,
    {
      variables: {
        email: email,
        password: password1,
        userId: iVariable,
        rToken: tVariable,
      },
      onCompleted: data => {
        setEmail("")
        setPassword1("")
        setPassword2("")
        setPassword("")
        setPasswordSet(true)
      },
    }
  )

  const checkCharacters = () => {
    var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/

    if (password1.match(decimal)) {
      setPassword(password1)
      return true
    } else {
      setWarning(
        "Please use a minimum of 8 characters including a number, a capitalized letter, a lowercase letter, and a symbol character like #$%^&*!."
      )
      return false
    }
  }

  const checkPassword = () => {
    if (password1 == "") {
      setWarning("Please enter a password")
      return false
    } else if (password2 == "") {
      setWarning("Please confirm password")
      return false
    } else if (password1 != password2) {
      setWarning("Passwords do not match. Please try again.")
      return false
    } else {
      setPassword(password1)
      return true
    }
  }

  const validate = () => {
    checkPassword() && checkCharacters() && handleSubmit()
  }

  return (
    <Layout>
      <div className={classes.root}>
        <Container maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Set a New Password
            </Typography>
            {passwordSet ? (
              <>
                <Alert severity="success">
                  <AlertTitle>Success!</AlertTitle>
                  Your password was reset.{" "}
                  <strong>Go ahead and sign in!</strong>
                </Alert>
                <Button
                  component={Link}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  to={"/my/"}
                >
                  Sign In
                </Button>
              </>
            ) : (
              <form
                className={classes.form}
                noValidate
                onSubmit={event => {
                  event.preventDefault()
                  validate(event)
                  // handleChange()
                }}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  helperText="Please use a minimum of 8 characters including a number, a capitalized letter, a lowercase letter, and a symbol character like #$%^&*!."
                  autoComplete="current-password"
                  value={password1}
                  onChange={event => setPassword1(event.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Confirm Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password2}
                  onChange={event => setPassword2(event.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={
                    loading ||
                    !email.trim() ||
                    !password1.trim() ||
                    !password2.trim()
                  }
                >
                  {loading ? "Just a sec" : "Set New Password"}
                </Button>
                <Grid container>
                  <Grid item>
                    {error && <Error />}
                    {warning.length > 3 && (
                      <Alert onClose={() => setWarning("")} severity="warning">
                        {warning}
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              </form>
            )}
          </div>
          <Box mt={8}></Box>
        </Container>
      </div>
    </Layout>
  )
}
const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#ffffff",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    display: "flex",
    flexDirection: "column",
  },
})
const NEW_PASSWORD_MUTATION = gql`
  mutation(
    $email: String!
    $password: String!
    $rToken: String!
    $userId: String!
  ) {
    updatePassword(
      email: $email
      password: $password
      rToken: $rToken
      userUid: $userId
    ) {
      user {
        id
        email
      }
    }
  }
`

export default withStyles(styles)(PasswordNew)
