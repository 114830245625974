import React from "react"

import PasswordNew from "../components/Sign/PasswordNew"
import { Router, Link } from "@reach/router"

const ResetPassword = props => (
  <>
    <PasswordNew path="./:user/:token" props={props} />
  </>
)

export default ResetPassword
